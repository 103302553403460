import React from "react";

import { Box, Typography } from "@mui/material";

import ShuttleVehicle from "globals/design-system/illustrations/ShuttleVehicle.svg";

type GreetingProps = {
  isSSO?: boolean;
};

function Greeting(props: GreetingProps) {
  const { isSSO } = props;
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        component="img"
        src={ShuttleVehicle}
        alt="shuttle-vehicle"
        width="128px"
        mb={1}
      />
      <Box mb={1}>
        <Typography variant="h2" textAlign="center">
          Welcome to the shuttle program
        </Typography>
      </Box>
      {!isSSO && (
        <Typography textAlign="center" variant="body1">
          Please enter your phone number below to sign in
        </Typography>
      )}
    </Box>
  );
}

export default Greeting;
