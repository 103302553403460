import React, { useEffect } from "react";

import { Box } from "@mui/material";

import { Greeting } from "./components";
import { LoginForm } from "./components/LoginForm";
import { RedirectPageOption } from "components/feature";
import { useLoadCompanyForUnauthenticated } from "globals/hooks";
import { LogoHeader } from "components/layout/components";
import { useHistory } from "react-router-dom";

// constants
const LOGIN_PAGE_WIDTH = "450px";

function LoginPage() {
  // hooks
  const { unauthenticatedCompany } = useLoadCompanyForUnauthenticated();
  const history = useHistory();

  const isEbay = unauthenticatedCompany?.name === "eBay";

  useEffect(() => {
    if (isEbay && unauthenticatedCompany?.id) {
      history.push(`/${unauthenticatedCompany.id}/login/ebay/sso`);
    }
  }, [isEbay, unauthenticatedCompany?.id, history]);

  return (
    <>
      {/* page header */}
      <LogoHeader />
      {/* Page Body */}
      <Box display="flex" justifyContent="center">
        <Box padding={2} maxWidth={LOGIN_PAGE_WIDTH}>
          <Box my={3}>
            <Greeting />
          </Box>
          <Box my={4}>
            <LoginForm />
          </Box>
          <Box my={3}>
            <RedirectPageOption
              statement="Don't have an account?"
              alternateName="Create Account"
              alternatePath={`/${unauthenticatedCompany?.id}/sign-up`}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default LoginPage;
