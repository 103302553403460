import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box } from "@mui/material";

import { loginFormDefaultValues } from "./util/formDefaultValues";
import { loginFormSchema } from "./util/formSchemaValidation";
import { RHFMobileField } from "components/ui/react-hook-form";
import { RHFMobileFieldMaskEnum } from "components/ui/react-hook-form/RHFMobileField";
import { useLoadCompanyForUnauthenticated, useSnackbar } from "globals/hooks";
import authApi from "globals/hooks/useAuth/utils/authApi";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

function LoginFormForm() {
  // state
  const [loading, setLoading] = useState(false);

  // hooks
  const { unauthenticatedCompany } = useLoadCompanyForUnauthenticated();
  const history = useHistory();
  const snackbar = useSnackbar();

  // set up react hook form
  const {
    control,
    formState: { errors },
    handleSubmit: validateLoginForm,
    watch,
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: loginFormDefaultValues,
    resolver: yupResolver(loginFormSchema),
  });

  // event handlers

  // submit form will be called when all inputs have passed the yup schema validation
  const submitForm = async () => {
    const { mobile } = watch();
    setLoading(true);

    const { status, error } = await authApi.sendCode({
      companyId: unauthenticatedCompany.id,
      mobilePhone: mobile,
    });

    setLoading(false);
    if (status !== 200) {
      const errorMessage = error?.message || error;

      snackbar.error(errorMessage);
    } else {
      history.push(`/${unauthenticatedCompany.id}/verify-code`, {
        maskedPhone: mobile,
      });
    }
  };

  // clicking on Create Account
  const handleLoginClick = () => {
    validateLoginForm(submitForm)();
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <Box my={1.5}>
        <RHFMobileField
          name="mobile"
          control={control}
          errors={errors}
          mask={RHFMobileFieldMaskEnum.Standard}
          label="Enter Mobile Phone Number"
          placeholder="Enter Mobile Phone Number"
        />
      </Box>
      <LoadingButton
        fullWidth
        onClick={handleLoginClick}
        loading={loading}
        variant="contained"
      >
        Sign In
      </LoadingButton>
    </form>
  );
}

export default LoginFormForm;
