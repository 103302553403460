import React, { useEffect, useState } from "react";
import { SAMLAuthProvider, signInWithPopup } from "firebase/auth";
import {
  useAuth,
  useLoadCompanyForUnauthenticated,
  useLoadUpcomingCompanyPassengerTicket,
  useSnackbar,
} from "globals/hooks";
import auth from "globals/utils/auth";
import authApi from "globals/hooks/useAuth/utils/authApi";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import LogoHeader from "components/layout/components/LogoHeader";
import { Box } from "@mui/material";
import Greeting from "pages/LoginPage/components/Greeting";

function LoginSSOPage() {
  // state
  const [loading, setLoading] = useState(false);

  // hooks
  const { unauthenticatedCompany } = useLoadCompanyForUnauthenticated();
  const { ticketData } = useLoadUpcomingCompanyPassengerTicket();
  const history = useHistory();
  const snackbar = useSnackbar();
  const { authStage } = useAuth();

  const handleLoginSaml = async () => {
    try {
      setLoading(true);
      const provider = new SAMLAuthProvider(
        process.env.REACT_APP_SAML_EBAY_PROVIDER_ID
      );
      const firebaseResponse = await signInWithPopup(auth, provider);

      const token = await firebaseResponse.user.getIdToken();

      const res = await authApi.loginSSO({
        companyId: unauthenticatedCompany.id,
        idToken: token,
        provider: "ebay",
      });

      setLoading(false);
      if (res.status !== 200) {
        snackbar.error(res?.error?.message);
      } else {
        if (ticketData) {
          history.push(`/${unauthenticatedCompany.id}/home`);
        } else {
          history.push(`/${unauthenticatedCompany.id}/trips`);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (authStage === "authenticated" && unauthenticatedCompany?.id) {
      history.push(`/${unauthenticatedCompany.id}/home`);
    }
  }, [authStage, unauthenticatedCompany?.id, history]);

  return (
    <>
      <LogoHeader />
      <Box display="flex" justifyContent="center">
        <Box padding={2} maxWidth={"450px"}>
          <Box my={3}>
            <Greeting isSSO />
          </Box>
          <LoadingButton
            fullWidth
            onClick={handleLoginSaml}
            loading={loading}
            variant="contained"
          >
            Sign In with eBay
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
}

export default LoginSSOPage;
