/**
 * @file PrivateRoute.tsx
 * Protected route HOC for redirecting unauthenticated users. Wrapper for react-router Route component
 * Reference: https://reacttraining.com/react-router/web/example/auth-workflow
 *
 */

import React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";

import {
  useAuth,
  useCompanyId,
  useLoadCompanyForUnauthenticated,
} from "globals/hooks";

function PrivateRoute({ children, ...rest }) {
  // hooks
  const { authStage } = useAuth();
  const companyId = useCompanyId();
  const { unauthenticatedCompany: company } =
    useLoadCompanyForUnauthenticated();

  const history = useHistory();

  const isEbay = company?.name === "eBay";

  if (authStage === "pending") return null;

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // authentication fails
        if (authStage === "rejected") {
          return (
            <Redirect
              to={{
                pathname: isEbay
                  ? `/${companyId}/login/ebay/sso`
                  : `/${companyId}/login`,
                search: history.location.search,
                state: { from: location },
              }}
            />
          );
        }

        // else
        return children;
      }}
    />
  );
}

export default PrivateRoute;
