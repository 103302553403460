/**
 * Interface for authenticating with server /auth endpoint.
 */
import axios from "axios";
import { fromGlobalId } from "globals/utils";
axios.defaults.withCredentials = true;

// constants
const AUTH_ENDPOINT = `${process.env.REACT_APP_API_URL}/auth/shuttle`;

type sendCodeProps = {
  mobilePhone: string;
  companyId: string;
};

type CreatePassengerProps = {
  firstName: string;
  lastName: string;
  mobilePhone: string;
  companyId: string;
  email: string;
};

// create-passenger
const createPassenger = async (props: CreatePassengerProps) => {
  const { firstName, lastName, mobilePhone, companyId, email } = props;

  try {
    const res = await axios.post(`${AUTH_ENDPOINT}/create-passenger`, {
      firstName,
      lastName,
      mobilePhone,
      companyId: fromGlobalId(companyId).id,
      email,
    });
    return {
      data: res.data,
      status: res.status,
    };
  } catch (error: any) {
    const { data, status } = error?.response || {};
    return {
      status,
      error: data,
    };
  }
};

// send-code
const sendCode = async (props: sendCodeProps) => {
  const { mobilePhone, companyId } = props;

  try {
    const res = await axios.post(`${AUTH_ENDPOINT}/send-code`, {
      mobilePhone,
      companyId: fromGlobalId(companyId).id,
    });

    return {
      data: res.data,
      status: res.status,
    };
  } catch (error: any) {
    const { data, status } = error?.response || {};

    return {
      status,
      error: data,
    };
  }
};

type loginProps = {
  mobilePhone: string;
  code: string;
  companyId: string;
};
// login
const login = async (props: loginProps) => {
  const { mobilePhone, code, companyId } = props;

  try {
    const res = await axios.post(`${AUTH_ENDPOINT}/login`, {
      code,
      mobilePhone,
      companyId: fromGlobalId(companyId).id,
    });

    return {
      data: res.data,
      status: res.status,
    };
  } catch (error: any) {
    const { data, status } = error?.response || {};

    return {
      status,
      error: data,
    };
  }
};

type logoutProps = {
  companyId: string;
};
// logout
const logout = async (props: logoutProps) => {
  const { companyId } = props;

  try {
    await axios.post(`${AUTH_ENDPOINT}/logout`, {
      companyId,
    });
  } catch (error: any) {
    console.log(error);

    const { data, status } = error?.response || {};

    return {
      status,
      error: data,
    };
  }
};

type loginSSOProps = {
  companyId: string;
  idToken: string;
  provider: string;
};
// login-sso
const loginSSO = async (props: loginSSOProps) => {
  const { companyId, idToken, provider } = props;

  try {
    const res = await axios.post(`${AUTH_ENDPOINT}/login/${provider}/sso`, {
      companyId: fromGlobalId(companyId).id,
      idToken,
    });

    return {
      data: res.data,
      status: res.status,
    };
  } catch (error: any) {
    const { data, status } = error?.response || {};

    return {
      status,
      error: data,
    };
  }
};

const authApi = {
  sendCode,
  login,
  logout,
  createPassenger,
  loginSSO,
};

export default authApi;
